<template>
  <div id="vue-admin-beautiful">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
    }
  },
}
</script>
<style lang="less">
@import '~@/vab/styles/vab.less';
</style>
