<template>
  <div class="vab-logo">
<!--    <vab-icon v-if="logo" :icon="logo"></vab-icon>-->
    <a-image :src="logo" :width="30" :preview="false"/>
    <span class="anticon"></span>
    <span>{{ title }}</span>
  </div>
</template>

<script>
  import logo from "@/assets/images/logo.png";
  import { computed } from 'vue'
  import VabIcon from '@/layout/vab-icon'
  import { useStore } from 'vuex'

  export default {
    name: 'VabLogo',
    components: { VabIcon },
    setup() {
      const store = useStore()
      return {
        //logo: computed(() => store.getters['settings/logo']),
        //title: computed(() => store.getters['settings/title']),
        //logo
        title: computed(() => store.getters['user/shopName'] ?? store.getters['settings/title']),
        logo:computed(() => {
          let shopLogo = store.getters['user/shopLogo']
          if(shopLogo){
            shopLogo=process.env.VUE_APP_BASE_IMG_URL+shopLogo
          }else {
            shopLogo = logo
          }
          return shopLogo
        }),
      }
    },
  }
</script>
<style lang="less" scoped>
  .vab-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    margin: 16px 5px;
    overflow: hidden;
    overflow: hidden;
    font-size: 15px;
    color: #fff;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
