import request from '@/utils/request'
import { tokenName } from '@/config'

/*export function register2(params) {
    return request.post('/front/user/register', params);
}

export function login2(params) {
    return request.post('/front/user/login', params);
}

export function submitFeedBack(params) {
    return request.post('/front/user/feedback', params);
}

export function comment(params) {
    return request.post('/front/user/comment',params);
}

export function deleteComment(id) {
    return request.delete(`/front/user/comment/${id}`);
}

export function updateComment(id,content) {
    return request.putForm(`/front/user/comment/${id}`,content);
}

export function getUserinfo2() {
    return request.get('/front/user');
}

export function updateUserInfo(userInfo) {
    return request.put('/front/user',userInfo);
}

export function listComments(params) {
    return request.get('/front/user/comments', { params });
}*/

const accessTokens = {
    admin: 'admin-accessToken',
    editor: 'editor-accessToken',
    test: 'test-accessToken',
}
export async function login(data) {
    /*return request({
        url: '/login',
        method: 'post',
        data,
    })*/
    return request.post('/shop/login', data);
    /*const accessToken = accessTokens[data.username]
    return {
        code: 200,
        msg: 'success',
        data: { accessToken },
    }*/
}
export function getShopInfo() {
    return request.get('/shop/info');
}

export async function socialLogin(data) {
    return request({
        url: '/socialLogin',
        method: 'post',
        data,
    })
}

export function getUserInfo(accessToken) {
    //此处为了兼容mock.js使用data传递accessToken，如果使用mock可以走headers
    /*return request({
        url: '/userInfo',
        method: 'post',
        data: {
            [tokenName]: accessToken,
        },
    })*/
    return request.get('/shop/user',);
    /*let roles = ['admin']
    let ability = ['READ']
    let username = 'admin'
    if ('admin-accessToken' === accessToken) {
        roles = ['admin']
        ability = ['READ', 'WRITE', 'DELETE']
        username = 'admin'
    }
    if ('editor-accessToken' === accessToken) {
        roles = ['editor']
        ability = ['READ', 'WRITE']
        username = 'editor'
    }
    if ('test-accessToken' === accessToken) {
        roles = ['admin', 'editor']
        ability = ['READ']
        username = 'test'
    }
    return {
        code: 200,
        msg: 'success',
        data: {
            roles,
            ability,
            username,
            'avatar':'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif',
        },
    }*/
}

export function logout() {
    /*return request({
        url: '/logout',
        method: 'post',
    })*/
    return {
        code: 200,
        msg: 'success',
    }
}

export function register() {
    return request({
        url: '/register',
        method: 'post',
    })
}

/**
 * 修改用户 密码
 */
export function editPass(data){
    return request.put('/shop/pass',data);
}
/**
 * 更换头像
 */
export function editAvatar(data) {
    return request.post(`/shop/edit/avatar`, data);
}
/**
 * 修改店铺logo图片
 */
export function editLogo(data) {
    return request.post(`/shop/edit/logo`, data);
}

/**
 * 获取图片验证码接口
 */
export function getImgVerifyCode() {
    return request.get(`/shop/img_verify_code`);
}
