<template>
  <div class="vab-avatar">
    <a-dropdown>
      <span class="ant-dropdown-link">
<!--        <a-avatar :src="avatar" />-->
        <span v-if="avatar">
          <a-avatar v-if="avatar&&avatar.toString().startsWith('http')" :src="avatar" />
          <a-avatar v-else :src="`${imgBaseUrl+avatar}`" />
        </span>
        <a-avatar v-else>{{ username.toString().slice(0,1) }}</a-avatar>
        {{ username }}
        <DownOutlined />
      </span>
      <template v-slot:overlay>
        <a-menu>
          <a-menu-item @click="gotoPerson">修改密码</a-menu-item>
          <a-menu-item @click="logout">退出登录</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
  import { recordRoute } from '@/config'
  import { DownOutlined } from '@ant-design/icons-vue'

  import { useStore } from 'vuex'
  import {computed,} from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  export default {
    name: 'VabAvatar',
    components: { DownOutlined },
    setup() {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()

      const logout = async () => {
        await store.dispatch('user/logout')
        if (recordRoute) {
          const fullPath = route.fullPath
          router.push(`/login?redirect=${fullPath}`)
        } else {
          router.push('/login')
        }
      }
      const gotoPerson = () => {
        router.push({name:'PersonInfo'})
      }

      return {
        avatar: computed(() => store.getters['user/avatar']),
        username: computed(() => store.getters['user/username']),
        imgBaseUrl: process.env.VUE_APP_BASE_IMG_URL,
        logout,
        gotoPerson,
      }
    },
  }
</script>
<style lang="less">
  .vab-avatar {
    .ant-dropdown-link {
      display: block;
      min-height: 64px;
      cursor: pointer;
    }
  }
</style>
